.testimonials-main {
    width: 1136px;
    margin: 75px auto;
    height: 610px;
    margin-bottom: 176px;
  }

  .testimonials-main h2 {
    margin: 0;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    color: var(--blue);
  }

  .testimonials-main .description {
    margin: 0;
    margin-top: 19px;
    text-align: center;
    font-size: 20px;
    line-height: 28px;
  }

  .testimonials-main .testimonials-container {
    position: relative;
    width: 100%;
    height: 473px;
    margin: 0 auto;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    padding-top: 77px;
  }

  .testimonials-main .testimonials-container .dotted {
    position: absolute;
    background-image: url("../../assets/dottedd.webp");
    background-size: cover;
    top: 0;
    left: 265px;
    width: 66px;
    height: 91px;
  }

  .testimonials-main .testimonials-container .dotted + .d2 {
    top: 15px;
    left: 805px;
  }

  .testimonials-main .testimonials-container .dotted + .d3 {
    top: 330px;
    left: 80px;
  }

  .testimonials-main .testimonials-container .dotted + .d4 {
    top: 330px;
    left: 987px;
  }

  .testimonials-main .testimonials-container .testb-container {
    position: absolute;
    top: 0;
    left: 330px;
    z-index: 100;
  }


  @media only screen and (min-width: 320px) and (max-width: 520px) {
    .testimonials-main {
      width: 100%;
      margin: 0px;
      height: auto;
      margin-bottom: 120px;
      margin-top: 40px;
    }

    .testimonials-main h2 {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }

    .testimonials-main .description {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 40px;
    }

    .testimonials-main .testimonials-container {
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0px;
      padding-top: 0px;
    }

    .testimonials-main .testimonials-container .dotted {
      display: none;
    }

    .testimonials-main .testimonials-container .testb-container {
      position: relative;
      left: 0px;
      top: 0px;
      width: 100%;
      height: auto;
      margin: 0px;
      padding: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .testimonials-main .testimonials-container .testb-container .testb {
      width: 100%;
      height: auto;
      margin: 0px;
      padding: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .testimonials-main .testimonials-container .testb-container .testb .testb-img {
      width: 100%;
      height: auto;
      margin: 0px;
      padding: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .testimonials-main .testimonials-container .testb-container .testb .testb-img img {
      width: 100%;
      height: auto;
      margin: 0px;
      padding: 0px;
    }

    .testimonials-main .testimonials-container .testb-container .testb .testb-text {
      width: 100%;
      height: auto;
      margin: 0px;
      padding: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .testimonials-main .testimonials-container .testb-container .testb .testb-text .testb-text-name {
      width: 100%;
      height: auto;
      margin: 0px;
      padding: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .testimonials-main .testimonials-container .testb-container .testb .testb-text .testb-text-name h3 {
      width: 100%;
      height: auto;
      margin: 0px;
      padding: 0px;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: var(--blue);
    }
  }
