.box {
    width: 280px;
    height: 254px;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    padding: 19px 21px 19px 22px;
    margin-bottom: 10px;
  }

  .box .p-box {
    font-size: 14px;
    width: auto;
    line-height: 25px;
  }

  .box .box-title {
    display: flex;
    justify-content: left;
    margin-bottom: 28px;
  }
.icon-container img{
    width: 25px;
    height: 25px;
}
  .box .box-title .icon-container {
    width: 44px;
    height: 44px;
    background-color: #f6f6f6;
    border-radius: 50%;
    padding: 7px 0 0 12px;
  }

  .box .box-title h3 {
    margin: 0 0 0 21px;
    font-size: 18px;
    line-height: 44px;
    text-align: center;
    display: inline-block;
  }

  .box p {
    border-top: 1.5px solid #c4c4c481;
    padding-top: 20px;
    font-size: 16px;
    line-height: 26px;
    color: #666666;
    text-align: left;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .box {
      width: 100%;
      height: 200px;
      padding: 15px 20px;
      margin: 20px 0 0 0;
    }
    .box h2 {
      font-size: 24px;
    }
    .box p {
      font-size: 16px;
    }
    .cards-container {
      margin-top: 25px;
    }
  }
