.main-card {
    border-radius: 10px;
    width: 554px;
    height: 420px;
    background-image: url(../../../assets/jorge.webp);
    background-size: cover;
    position: relative;
    overflow: hidden;
  }

  .main-card .blue-shadow {
    position: absolute;
    width: 100%;
    height: 422px;
    opacity: 0.15;
    top: 0;
    left: 0;
    border-radius: unset;
  }

  .main-card .card-content {
    padding: 10px;
  }

  .main-card .card-content h3 {
    margin: 0 0 16px 0;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-size: 17px;
    line-height: 30px;
  }

  .main-card .card-content p {
    margin-top: -20px;
    color: rgb(0, 0, 0);
    font-size: 15px;
    font-weight: bold;
    text-align: start;
  }

  /* .main-card .card-content span {
    background-color: rgba(0, 44, 61, 0.166);
    padding: 5px;
    border-radius: 10px;
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-weight: bold;
    text-align: start;
  } */

  @media only screen and (min-width: 320px) and (max-width: 540px) {
    .main-card .card-content {
      padding: 10px;
    }
    .main-card .card-content span {
      background-color:transparent;
      padding: 5px;
      border-radius: 10px;
      color: rgb(0, 0, 0);
      font-size: 15px;
      font-weight: bold;
      text-align: start;
    }
    .main-card .card-content h3 {
      margin: 0 0 16px 0;
      color: rgb(0, 0, 0);
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
    }
    .main-card {
      width: 100%;
      height: 300px;
      padding: 0 20px;
      margin: 0;
      margin-bottom: 20px;
    }
  }
