.testimonial1-container {
    width: 358px;
    height: 396px;
  }

  .testimonial1-container .text1-container {
    position: relative;
    width: 100%;
    height: 266px;
    background-color: rgb(48, 86, 211);
    padding: 22.2px 43px 66px 43px;
    box-shadow: 0px 10px 20px rgba(11, 82, 188, 0.15);
    border-radius: 10px;
  }
  .testimonial1-container .text1-container p{
    color: white
  }

  .testimonial1-container .text1-container img {
    display: block;
    margin: 0 auto 19.2px;
  }

  .testimonial1-container .text1-container p {
    font-size: 16px;
    line-height: 26px;
  }

  .testimonial1-container .text1-container .profile-pic {
    position: absolute;
    width: 108px;
    height: 108px;
    border-radius: 50%;
    margin-bottom: 0;
    top: 219px;
    left: 125px;
    object-fit: cover;
  }

  .testimonial1-container .text1-container .blue-shadow {
    width: 108px;
    height: 108px;
    background-color: #00000091;
    opacity: 0.15;
    border-radius: 50%;
    top: 219px;
    left: 125px;
  }

  .testimonial1-container .name {
    margin-top: 80px;
    font-size: 16px;
    line-height: 26px;
    font-weight: bold;
    text-align: center;
  }

  .testimonial1-container .position {
    font-size: 14px;
    line-height: 26px;
    font-weight: normal;
    text-align: center  ;
  }

  .text1-container .quote{
    width: 60px;
    height: 60px;
  }


  @media only screen and (min-width: 320px) and (max-width: 520px) {
    .testimonial1-container {
      height: 500px;
      margin-bottom: 30px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
    }
  }
