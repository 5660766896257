:root {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
*,
*::after,
*::before {
  box-sizing: inherit;
}

body {
  background-color: black;
  font-family: "Muli", sans-serif;
}

@media only screen and (min-width: 320px) and (max-width: 520px) {
  .site-container {
    overflow-x: hidden;
    overflow-y: hidden;
    width: 90%;
    margin: auto;
  }
}
