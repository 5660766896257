.card {
    box-shadow: 0px 0px 10px #00000035;
    font-family: "Muli", sans-serif;
    width: 260px;
    height: 450px;
    background-color: #ffffff;
    border-radius: 10px;
    overflow: auto;
    position: relative;
    margin-top: 6px;
    margin-bottom: 30px;
  }

  .img_user_icon{
    width: 20px;
    height: 20px;
    margin-left: 2px;
    margin-right: 15px;
    object-fit: cover;
  }

  .card .cont-btn-ver {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card .cont-btn-ver .btn-ver {
    padding-left: 7px;
    padding-right: 7px;
    background-color: rgb(22, 178, 32);
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    color: rgb(255, 255, 255);
    width: 100px;
    text-align: center;
    font-size: 12px;
  }

  .card .card-image {
    width: 100%;
    height: 252px;
    object-fit: cover;
  }

  .card .blue-shadow {
    width: 100%;
    height: 252px;
    background-color: #00000091;
    opacity: 0.15;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: unset;
  }

  .card .card-body {
    padding: 5px 10px 0 18px;
  }

  .card .card-content {
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 23px;
  }

  .card .card-content a {
    text-decoration: none;
    color: gray;
    font-size: 12px;
    line-height: 30px;
    font-weight: bold;
  }

  .card .card-content a span {
    margin-left: 5px;
  }

  .card .card-content a img {
    display: inline;
  }

  .card .card-content h4 {
    margin: 0;
    margin-top: 5px;
  }

  .card .card-content h4 .card-title {
    color: black;
    font-size: 17px;
    line-height: 30px;
    font-weight: bold;
  }

  .card .card-content p {
    overflow-y: auto;
    text-align: left;
    margin: 0;
    margin-top: 5px;
    color: gray;
    font-size: 14px;
    line-height: 30px;
    font-weight: bold;
  }

  .card .card-content p span {
    color: #c4c4c4;
    font-weight: 600;
  }

  .card .card-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 13px;
  }

  .card .card-footer .footer-item p {
    display: inline;
    margin-left: 5px;
    font-size: 12px;
    line-height: 30px;
    color: gray;
  }

  .card .card-footer .footer-item img {
    display: inline;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .card {
      width: 100%;
      height: auto;
      padding: 0 20px;
      margin-bottom: 25px;
    }
    .card .card-content p{
      font-size: 13px;
      line-height: 20px;
    }
    .card-image {
      width: 100%;
      object-fit:cover  !important;
    }
  }
