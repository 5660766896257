.header-container {
  background-color: rgb(255, 255, 255);
  height: 611px;
  width: 100%;
  padding-left: 150px;
  margin: 0 auto;
  overflow: hidden;
}
.button-fixed {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 168px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(77, 192, 77);
  height: 45px;
  padding-left: 10px;
  padding-right: 10px;
  opacity: 0.9;

  z-index: 1000;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.326);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 10px;
  animation: saltar 2s infinite;
}

@keyframes saltar {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}


.img-w {
  width: 30px;
  height: 30px;
}
.round-pic {
  width: 324px;
  height: 524px;
  background-image: url("../../assets/logo.webp");
  background-size: cover;
  border-radius: 50%;
  background-position: center;
  position: absolute;
  top: 53px;
  left: 1037px;
  z-index: 1;
}

.blue-shadow {
  position: absolute;
  width: 794px;
  height: 794px;
  background-color: rgba(7, 114, 34, 0.267);
  opacity: 0.25;
  top: -83px;
  left: 937px;
  border-radius: 50%;
  z-index: 0;
}

.header-navbar {
  display: flex;
  justify-content: space-between;
  margin-top: 38px;
}
.header-scroll {
  position: fixed;
  top: -40px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 65px;
  padding-left: 150px;
  padding-right: 150px;
  padding-top: 20px;
  background-color: #161b2292;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.326);
  transition: all 0.3s ease-in-out;
}

.links-color {
  font-family: "Muli", sans-serif;
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  margin-right: 40px;
}
.l-color {
  font-family: "Muli", sans-serif;
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  margin-right: 40px;
}

.header-navbar .contact-us {
  margin-top: 0;
  z-index: 10;
  width: 200px;
  height: 30px;
  background-color: white;
  border: unset;
  border-radius: 10px;
  color: rgb(0, 82, 0);
  font-size: 16px;
  line-height: 30px;
}

.header-navbar .contact-us:hover {
  cursor: pointer;
}

.header-navbar .top-bar {
  width: 721px;
  display: flex;
  justify-content: space-between;
}

.title {
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  color: rgb(0, 82, 0);
  font-size: 1rem;
  font-weight: 600;
}
.title-c {
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  font-weight: 700;
}

.header-navbar .top-bar .links a:last-of-type {
  margin-right: 0;
}

.header-navbar .top-bar .links a:hover {
  cursor: pointer;
  border-bottom: 2px solid rgb(0, 82, 0);
  opacity: 0.8;
  transition: all 0.3s ease-in-out;
}

.message {
  margin-top: 78px;
  width: 625px;
  margin-bottom: 66px;
}

.message h1 {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  line-height: 65px;
  font-weight: 500;
  width: 800px;
  color: rgb(0, 0, 0);
}

.message h2 {
  border-bottom: 2px solid rgb(0, 82, 0);
  padding-bottom: 5px;
}

.message p {
  margin-top: 22px;
  margin-bottom: 0;
  font-family: "Muli", sans-serif;
  font-weight: 18px;
  line-height: 23px;
  font-size: 15px;
  text-align: start;
  color: gray;
}

.button {
  position: relative;
  top: 30px;
  width: 221px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(2, 126, 2);
  color: white;
  font-weight: bold;
}

.button:hover {
  cursor: pointer;
}

.cont-butons {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: row;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .round-pic {
    display: none;
  }
  .blue-shadow {
    display: none;
  }
  header {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header-container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .header-container .header-navbar {
    margin-top: 22px;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .header-container .header-navbar .top-bar {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .header-container .header-navbar .top-bar .img-header-logo {
    width: 300px;
    height: 300px;
    margin-bottom: -60px;
    margin-top: -45px;
  }

  .header-container .header-navbar .top-bar .title {
    margin: 0;
    font-size: 1.3rem;
  }

  .header-container .header-navbar .top-bar .links {
    display: none;
  }

  .header-container .header-navbar .contact-us {
    margin-top: 20px;
    width: 200px;
    height: 30px;
    background-color: white;
    border: unset;
    border-radius: 10px;
    color: rgb(0, 82, 0);
    font-size: 16px;
    line-height: 30px;
  }

  .header-container .header-navbar .contact-us:hover {
    cursor: pointer;
  }

  .header-container .message {
    margin-top: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 75px;
    text-align: center;
  }

  .header-container .message .button {
    width: 98%;
    margin: 0 auto;
  }

  .header-container .message h1 {
    margin-top: 15px;
    width: 100%;
    font-size: 1.8rem;
    line-height: 2.5rem;
  }

  .header-container .message p {
    text-align: center;
    width: 95%;
    margin: 20px auto;
  }
  .button {
    width: 120px;
    position: relative;
    right: -5px;
    margin-left: 5px;
  }
  .btn-w {
    position: relative;
    right: 5px;
    margin-left: 15px;
  }
  .btn-f {
    position: relative;
    left: 15px;
  }
  .cont-butons {
    width: 60%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
  }
  .button-v2 {
    position: relative;
    left: -5px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1840px) {
  header .header-container .header-navbar .top-bar .img-header-logo {
    display: none;
  }
}
